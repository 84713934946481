<template>
	<div style="padding:15px;">
		<div style="padding: 15px;background-color: #fff;">
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">搜索：</label>
				<el-input placeholder="请输入产品关键词" style="width:95%;" v-model="keyword" @change="ser">
					<el-button slot="append" icon="el-icon-search" @click="ser"></el-button>
				</el-input>
			</div>
		</div>
		<div style="padding: 15px;background-color: #fff;margin-top: 10px;">
			<div class="newsf">
				<el-tabs v-model="activeName" @tab-click="handleClick">
				    <el-tab-pane :label="typeitem.name" :name="typeitem.index" v-for="(typeitem,index1) in typelist">
						<div class="newsitem" v-for="(item,index) in productlist">
							<el-image class="newsimg" :src="item.imgurl" fit="fill"></el-image>
							<div class="newsitem_con">
								<h4>{{item.title}}</h4>
								<div class="newsitem_con_2">
									<div>{{item.updatatime}}</div>
									<div>
										<el-button type="primary" icon="el-icon-edit" circle @click="updata(item.id)"></el-button>
										<el-button type="danger" icon="el-icon-delete" circle @click="del(item.id)"></el-button>
									</div>
								</div>
							</div>
						</div>
					</el-tab-pane>
				  </el-tabs>
			</div>
			<el-pagination
			  background
			  layout="prev, pager, next"
			  @current-change="chagepage"
			  :total="pagetotal">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default Vue.extend({
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				productlist:[],
				keyword:'',
				typelist:[],
				page:'',
				typeid:'',
				activeName:'',
				pagetotal:1
			}
		},
		mounted() {
			// 模拟 ajax 请求，异步渲染编辑器
			this.$get("/types/gettype").then(res2=>{
				this.typelist = res2.data
				this.typeid = this.typelist[0].id
				this.typelist.map((item,index)=>{
					let ids = index+1
					item.index = ids.toString()
				})
			})
			this.getdata()
		},
		methods: {
			handleClick(e){
				this.typeid = this.typelist[e.index].id
				this.getdata(this.keyword)
			},
			chagepage(e){
				// 分页改变页数
				this.getdata(this.keyword,e)
			},
			getdata(keyword = '',page = 1,type = ''){
				this.$get("/product/getproduct").then(res2=>{
					this.pagetotal = res2.data.length
				})
				
				this.$get("/product/serproduct",{
					keyword:keyword,
					page:page,
					typeid:this.typeid,
				}).then(res=>{
					if(keyword!=''){
						this.pagetotal = res.data.length
					}
					console.log(this.productlist);
					this.productlist = res.data
				})
			},
			ser(){
				this.getdata(this.keyword)
			},
			updata(id){
				// 编辑产品
				this.$router.push({ path: `updataproduct/${id}` })
			},
			del(id){
				// 删除产品
				this.$confirm('确定要删除吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res1)=>{
					if(res1 == 'confirm'){
						this.$post("/product/delproduct",{
							id:id
						}).then(res=>{
							this.$message({
								type: 'success',
								message: '已删除'
							});
							
							this.getdata(this.keyword,this.page)
						})
					}
				})
			}
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.newsf{
		width: 98%;
		border-bottom: 2px solid #ebebeb;
		padding-bottom: 15px;
		margin: 15px auto;
	}
	.newsitem{
		display: flex;
	}
	.newsitem_con_2{
		display: flex;
		margin-top: 30px;
		justify-content: space-between;
	}
	.newsitem_con_2 div{
		font-size: 12px;
		color: #999;
		line-height: 30px;
	}
	.newsimg{
		width: 80px;
		height:80px;
		border-radius: 10px;
		margin-right: 20px;
	}
	.newsitem_con{
		width: 800px;
	}
	.newsitem_con_1{
		font-size: 14px;
		line-height: 22px;
	}
	.newsitem_con h4{
		margin-bottom: 10px;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #a5a5a5;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>